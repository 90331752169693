<template>
    <card-list>
        <subheader-list-item icon="fa-calendar-alt" title="Itinerary"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Day" label-position="inside">
                        <b-select expanded v-model="flight.day_id">
                            <option :value="0">No Itinerary Day</option>
                            <option :value="i.id" v-for="i in itinerary">{{i.title}} ({{i.day_on | moment('MMM Do, YYYY')}})</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Second Day (optional)" label-position="inside">
                        <b-select expanded v-model="flight.second_day_id">
                            <option :value="0">No Itinerary Day</option>
                            <option :value="i.id" v-for="i in itinerary">{{i.title}} ({{i.day_on | moment('MMM Do, YYYY')}})</option>
                        </b-select>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="flight.has_arrived">Participant Arrived/Accounted For (During Trip)</b-switch>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-align-left" title="Flight information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <div class="mb-2 has-text-weight-bold is-size-7">Arranged By</div>

                    <b-radio v-model="flight.arranged_by" name="name" native-value="tv" class="mr-5">
                        Tennis Ventures
                    </b-radio>
                    <b-radio v-model="flight.arranged_by" name="name" native-value="self">
                        Participant
                    </b-radio>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Applies To" label-position="inside">
                        <b-select expanded v-model="flight.applies_to">
                            <option value="primary">{{registration.user.last_name}}, {{registration.user.first_name}} (Primary)</option>
                            <option value="secondary" v-if="registration.second_user">{{registration.second_user.last_name}}, {{registration.second_user.first_name}} (Secondary)</option>
                            <option value="both" v-if="registration.second_user">Both Participants</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="flight.primary_flight">This is an Primary Flight</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Operator" label-position="inside">
                        <b-input v-model="flight.operator"></b-input>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Flight Number" label-position="inside">
                        <b-input v-model="flight.flight_number"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Class" label-position="inside">
                        <b-select expanded v-model="flight.travel_class">
                            <option value="first">First</option>
                            <option value="business">Business</option>
                            <option value="premium-economy">Premium Economy</option>
                            <option value="economy">Economy</option>
                            <option value="tbd">TBD</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Seat Preference" label-position="inside">
                        <b-select expanded v-model="flight.seat_preference">
                            <option value="window">Window</option>
                            <option value="aisle">Aisle</option>
                            <option value="window-adjacent">Window & Adjacent</option>
                            <option value="aisle-adjacent">Aisle & Adjacent</option>
                            <option value="none">No Preference</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Seat" label-position="inside">
                        <b-input v-model="flight.seat"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Flight Cost (Hidden)" label-position="inside">
                        <b-input v-model="flight.cost"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Reference Number" label-position="inside">
                        <b-input v-model="flight.reference_number"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Additional Flight Notes" label-position="inside">
                        <b-input type="textarea" v-model="flight.flight_notes"></b-input>
                    </b-field>
                </div>
            </div>

        </container-list-item>

        <subheader-list-item icon="fa-plane-departure" title="Departure information"/>

        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <div v-if="!flight.depart_airport">
                        <b-field label="Departure Airport" label-position="inside">
                            <b-autocomplete
                                    :data="airportResults"
                                    field="title"
                                    :loading="isFetching"
                                    @typing="searchAirports"
                                    @select="selectDepart"
                                    icon="search"
                                    placeholder="Search Airports...">

                                <template slot-scope="props">
                                    <i class="fas fa-map-marker-alt mr-2"></i>{{props.option.name}} ({{props.option.iata}})
                                    <br>
                                    <small>
                                        {{props.option.city}}, {{props.option.country}}
                                    </small>
                                </template>
                                <template slot="empty">No results.</template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div v-else>
                        <div class="columns">
                            <div class="column">
                                <div class="is-size-7 has-text-weight-bold">Departure Airport</div>
                                <div class="mt-3"><i class="fas fa-map-marker-alt mr-2"></i>{{flight.depart_airport.name}} ({{flight.depart_airport.iata}})</div>
                                <div class="is-size-7">{{flight.depart_airport.city}}, {{flight.depart_airport.country}}</div>
                            </div>
                            <div class="column has-text-right">
                                <b-button size="is-small" outlined @click="clearDepart()"><i class="fal fa-times mr-2"></i>Remove</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Departure Date" label-position="inside">
                        <b-input type="date" v-model="flight.depart_date"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Departure Time" label-position="inside">
                        <b-input type="time" v-model="flight.depart_time"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>

        <subheader-list-item icon="fa-plane-arrival" title="Arrival information"/>

        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <div v-if="!flight.arrive_airport">
                        <b-field label="Arrival Airport" label-position="inside">
                            <b-autocomplete
                                    :data="airportResults"
                                    field="title"
                                    :loading="isFetching"
                                    @typing="searchAirports"
                                    @select="selectArrive"
                                    icon="search"
                                    placeholder="Search Airports...">

                                <template slot-scope="props">
                                    <i class="far fa-map-marker-alt mr-2"></i>{{props.option.name}} ({{props.option.iata}})
                                    <br>
                                    <small>
                                        {{props.option.city}}, {{props.option.country}}
                                    </small>
                                </template>
                                <template slot="empty">No results.</template>
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div v-else>
                        <div class="columns">
                            <div class="column">
                                <div class="is-size-7 has-text-weight-bold">Arrival Airport</div>
                                <div class="mt-3"><i class="fas fa-map-marker-alt mr-2"></i>{{flight.arrive_airport.name}} ({{flight.arrive_airport.iata}})</div>
                                <div class="is-size-7">{{flight.arrive_airport.city}}, {{flight.arrive_airport.country}}</div>
                            </div>
                            <div class="column has-text-right">
                                <b-button size="is-small" outlined @click="clearArrive()"><i class="fal fa-times mr-2"></i>Remove</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Arrival Date" label-position="inside">
                        <b-input type="date" v-model="flight.arrive_date"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Arrival Time" label-position="inside">
                        <b-input type="time" v-model="flight.arrive_time"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>

        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="flight.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                            <option value="ticketed">Ticketed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="flight.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="flight.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :true-value="1" :false-value="0" v-model="flight.task_complete">Complete/Not Tracked</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="flight.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import debounce from 'debounce';
    import {client as http} from '../../http_client';
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    import {mapGetters} from "vuex";

    export default {
        name: 'FlightForm',
        props: {
            flight: {
                type: Object,
                required: true
            },
            registration: {
                type:Object
            },
            itinerary: {
                type:Array
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        data: function () {
            return {
                isFetching: false,
                airportResults: null,
            };
        },
        computed: {
            ...mapGetters(['assignees'])
        },
        methods: {
            searchAirports: debounce(function (name) {
                if (!name.length) {
                    this.airportResults = [];
                    return;
                }
                this.isFetching = true;
                http.get(`/api/airports/${name}`).then(response => {
                    this.airportResults = response.data;
                }).catch((error) => {
                    this.airportResults = [];
                    throw error;
                }).finally(() => {
                    this.isFetching = false;
                });
            }, 500),
            selectDepart(option) {
                this.flight.depart_airport = option;
                this.flight.depart_airport_id = option.id;
                this.flight.depart_tz = option.tz_database;
            },
            selectArrive(option) {
                this.flight.arrive_airport = option;
                this.flight.arrive_airport_id = option.id;
                this.flight.arrive_tz = option.tz_database;
            },
            clearDepart() {
                this.flight.depart_airport = null;
                this.flight.depart_airport_id = null;
                this.flight.depart_tz = null;
            },
            clearArrive() {
                this.flight.arrive_airport = null;
                this.flight.arrive_airport_id = null;
                this.flight.arrive_tz = null;
            }
        }
    };
</script>
