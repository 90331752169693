<template>
    <div>
        <card-header title="Edit Flight" icon="fa-plane-departure"/>

        <card-toolbar>
            <template #right>
                <button @click="$openCard('copy-flight', {registrationId: props.registrationId, flightId: props.flightId}, card)"><i class="fas fa-copy mr-2"/>Copy Flight</button>
            </template>
        </card-toolbar>

        <card-body>
            <card-list>
                <registration-list-item :card="card" :registration="registration" @click="$openCard('registration', {registrationId: registration.id}, card)"/>
            </card-list>
            <flight-form :flight="flight" :registration="registration" :itinerary="itinerary"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteFlight"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Flight?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!flight.depart_airport || !flight.arrive_airport || !flight.depart_date || !flight.depart_time || !flight.arrive_date || !flight.arrive_time || !flight.flight_number" @click="submit"><i class="fas fa-check mr-2"></i>Save Flight</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';
    import CardHeader from '../../TIER/components/CardHeader';
    import CardBody from '../../TIER/components/CardBody';
    import CardFooter from '../../TIER/components/CardFooter';
    import FlightForm from './FlightForm';
    import RegistrationListItem from "../../components/RegistrationListItem";

    import async from 'async';
    import CardList from "../../TIER/components/CardList";
    import {mapActions} from "vuex";
    import CardToolbar from "../../TIER/components/CardToolbar.vue";

    export default {
        props: ['card', 'props'],
        components: {CardToolbar, CardList, RegistrationListItem, CardFooter, CardBody, CardHeader, FlightForm},
        data: function() {
            return {
                flight: {},
                registration: {trip: {}, user: {}},
                itinerary: [],
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadFlight: function() {
                this.$emit('loading', true);
                this.dirty = null;

                async.series([
                    // Get flight
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/flights/' + this.props.flightId, {force: true}).then(response => {
                            this.flight = response.data;
                            cb();
                        });
                    },
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    },
                    // Get itinerary
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/itinerary', {force: true}).then(response => {
                            this.itinerary = response.data.days;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/registrations/' + this.props.registrationId + '/flights/' + this.props.flightId, this.flight, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('flights', {registrationId: this.props.registrationId});
                    this.$reloadCard('itinerary', {registrationId: this.props.registrationId});
                    this.$reloadCard('events', {dayId: this.props.dayId});
                    this.$reloadCard('tasks');
                    this.$reloadCard('task-details', {type: 'flights'});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteFlight() {
                if (this.confirmDelete) {
                    http.delete('/api/registrations/' + this.props.registrationId + '/flights/' + this.props.flightId).then(response => {
                        this.$reloadCard('flights');
                        this.$reloadCard('tasks');
                        this.$reloadCard('task-details', {type: 'flights'});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.flightId': function(newProps) {
                this.loadFlight();
            },
            'flight': {
                deep: true,
                handler: function() {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this flight? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadFlight();
        }
    };
</script>
